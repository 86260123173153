let key = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://triangular-arbitrage-api.wealwin.com";
  key = {
    API_URL: API_URL,
    SOCKET_URL: API_URL,
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    API_URL: `${API_URL}:2060`,
    SOCKET_URL: `${API_URL}:2060`,
  };
}

export default key;