import logoImg from "../../assets/images/logo.png"
function Navbar() {

    return (
        
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark adminNav">
                <div className="container-fluid">
            <a href="/"><img src={logoImg} alt="Arbitribe" className="img-fluid" /></a>
                <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                >
                  
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="/dashboard"
                            >
                                Dashboard
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="/opportunities"
                            >
                                Opportunities
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
            </nav>
    );
}

export default Navbar;