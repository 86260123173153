function notFound() {
    return (
        <div style={{ height: "75vh" }} className="container valign-wrapper">
            <div className="row">
                <div className="col s12 center-align">
                    <h1>Not Found</h1>
                </div>
            </div>
        </div>
    );
}
export default notFound;