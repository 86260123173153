import { useState, useEffect } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

// import action
import { spotOrderHistory } from "../../actions/dashboard";

function Orders() {
    // state
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [sortOrder, setSortOrder] = useState({
        column: "_id",
        order: "desc",
    });
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const configdata = {
        page_size: pageSize,
        sortOrder: sortOrder,
        length_menu: [10, 20, 50],
        no_data_text: "No orders found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const historyColumns = [
        {
            key: "_id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "firstCurrency",
            text: "Base Currency",
            className: "pairName",
            align: "left",
            sortable: true,
        },
        {
            key: "secondCurrency",
            text: "Quote Currency",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "orderType",
            text: "Type",
            className: "Type",
            align: "left",
            sortable: true,
        },
        {
            key: "side",
            text: "Side",
            className: "Side",
            align: "left",
            sortable: true,
        },
        {
            key: "averagePrice",
            text: "Average",
            className: "Average",
            align: "left",
            sortable: true,
            cell: (record) => {
                return record.averagePrice ? parseFloat(parseFloat(record.averagePrice).toFixed(8)): 0;
            },
        },
        {
            key: "price",
            text: "Price",
            className: "Price",
            align: "left",
            sortable: true,
            cell: (record) => {
                return record.orderType == "limit" ? record.price ? parseFloat(parseFloat(record.price).toFixed(8)): 0: "Market";
            },
        },
        {
            key: "filledQuantity",
            text: "Executed",
            className: "Excuted",
            align: "left",
            sortable: true,
            cell: (record) => {
                return record.filledQuantity ? parseFloat(parseFloat(record.filledQuantity).toFixed(8)) : 0;
            },
        },
        {
            key: "quantity",
            text: "Amount",
            className: "Amount",
            align: "left",
            sortable: true,
            cell: (record) => {
                return record.quantity ? parseFloat(parseFloat(record.quantity).toFixed(8)) : 0;
            },
        },
        {
            key: "orderValue",
            text: "Total",
            className: "Total",
            align: "left",
            sortable: true,
            cell: (record) => {
                return record.orderValue ? parseFloat(parseFloat(record.orderValue).toFixed(8)) : 0;
            },
        },
        {
            key: "createdAt",
            text: "Date",
            className: "Date",
            align: "left",
            sortable: true,
            cell: (record) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            },
        },
    ];

    const fetchOrders = async () => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                search,
                sortOrder,
                limit: pageSize,
                offset,
            };
            setLoading(true);
            let { status, result, count } = await spotOrderHistory(data);
            setLoading(false);
            if (status != false) {
                setOrders(result);
                setTotalCount(count);
            }
        } catch (err) {}
    };

    const filter = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sort_order = data.sort_order ? data.sort_order : sortOrder;
        setOrders([]);
        setOffset(offset);
        setSearch(filter);
        setSortOrder(sort_order);
    };

    useEffect(() => {
        fetchOrders();
    }, [offset, search, sortOrder]);

    return (
      
            <ReactDatatable
                className="historyCryptoTable w-100"
                records={orders}
                columns={historyColumns}
                config={configdata}
                dynamic={true}
                onChange={filter}
                loading={loading}
                total_record={totalCount}
            />
       
    );
}

export default Orders;