// import config
import axios from "../config/axios";

export const newBot = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/newBot`,
            data,
        });
        return {
            status: "success",
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getBots = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getBots`,
            data,
        });
        return {
            status: "success",
            result: respData.data.result,
            count: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const getWallet = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getWallet`,
            data,
        });
        return {
            status: "success",
            result: respData.data.result,
            count: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const changeBotStatus = async (id) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/changeBotStatus`,
            data: { id },
        });
        return {
            status: "success",
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: "failed",
            message: err.response.data.message,
        };
    }
};

export const getOpportunities = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getOppertunities`,
            data,
        });
        return {
            status: "success",
            result: respData.data.result,
            count: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};

export const spotOrderHistory = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/spotOrderHistory`,
            data,
        });
        return {
            status: "success",
            result: respData.data.result,
            count: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: "failed",
        };
    }
};
