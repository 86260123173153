import { useState, useEffect } from "react";
import { Form } from "react-bootstrap-v5";
import ReactDatatable from "@ashvin27/react-datatable";

// import component
import Navbar from "../partials/Navbar";

// import action
import { newBot, getBots, getWallet, changeBotStatus } from "../../actions/dashboard";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
    botId: "",
    botName: "",
    intervalTime: 0,
    intervalType: "minutes",
    commonAsset: "USDT",
    orderAmount: 0,
    targetPercentage: 0,
};

function Dashboard() {
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bots, setBots] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [sortOrder, setSortOrder] = useState({
        column: "_id",
        order: "desc",
    });
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const [walletLoading, setWalletLoading] = useState(true);
    const [wallets, setWallets] = useState([]);
    const [walletPageSize, setWalletPageSize] = useState(10);
    const [walletOffset, setWalletOffset] = useState(0);
    const [walletSortOrder, setWalletSortOrder] = useState({
        column: "_id",
        order: "desc",
    });
    const [walletSearch, setWalletSearch] = useState("");
    const [walletTotalCount, setWalletTotalCount] = useState(0);

    const {
        botId,
        botName,
        intervalTime,
        intervalType,
        commonAsset,
        orderAmount,
        targetPercentage,
    } = formValue;

    const configdata = {
        page_size: pageSize,
        sortOrder: sortOrder,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: "No bot found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        key_column: "_id",
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const historyColumns = [
        {
            text: "Name",
            key: "name",
            className: "name",
            sortable: true,
        },
        {
            text: "Base",
            className: "commonAsset",
            key: "commonAsset",
            sortable: true,
        },
        {
            text: "Amount",
            className: "orderAmount",
            key: "orderAmount",
            sortable: true,
            cell: (record) => {
                return `${parseFloat(
                    parseFloat(record.orderAmount.toFixed(8))
                )} ${record.commonAsset}`;
            },
        },
        {
            text: "Total Fee (%)",
            className: "targetPercentage",
            key: "targetPercentage",
            sortable: true,
        },
        {
            text: "Interval Time",
            key: "intervalTime",
            className: "Ad Type",
            sortable: false,
            cell: (record) => {
                return `${record.intervalTime} ${record.intervalType}`;
            },
        },
        {
            text: "Status",
            key: "status",
            className: "status",
            sortable: true,
            cell: (record) => {
                return (
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => changeStatus(record._id)}
                        style={{ marginRight: "5px" }}
                    >
                        {record.status == true ? "Active" : "In Active"}
                    </button>
                );
            },
        },
        {
            text: "Action",
            key: "action",
            className: "action",
            sortable: false,
            cell: (record) => {
                return (
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => editRecord(record)}
                        style={{ marginRight: "5px" }}
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                );
            },
        },
    ];

    const walletConfigdata = {
        page_size: walletPageSize,
        sortOrder: walletSortOrder,
        length_menu: [10, 20, 50],
        no_data_text: "No wallet found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        key_column: "_id",
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const walletColumns = [
        {
            text: "Name",
            key: "name",
            className: "name",
            sortable: true,
        },
        {
            text: "Available",
            className: "available",
            key: "available",
            sortable: true,
        },
        {
            text: "In Order",
            className: "inOrder",
            key: "inOrder",
            sortable: true,
            cell: (record) => {
                return record.inOrder ? record.inOrder:0;
            },
        }
    ];

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        setValidateError({ ...validateError, ...{ [name]: "" } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        try {
            const { status, message, error } = await newBot(formValue);
            setLoader(false);
            if (status == "success") {
                setFormValue(initialFormValue);
                toastAlert("success", message);
                fetchBots();
            } else {
                if (error) setValidateError(error);
                if (message) toastAlert("error", message);
            }
        } catch (err) {}
    };

    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    const changeStatus = async (id) => {
        try {
            const { status, message } = await changeBotStatus(id);
            if (status == "success") {
                toastAlert("success", message);
                fetchBots();
            } else {
                toastAlert("error", message);
            }
        } catch (err) {}
    };

    const editRecord = async (record) => {
        setFormValue({
            botId: record._id,
            botName: record.name,
            intervalTime: record.intervalTime,
            intervalType: record.intervalType,
            commonAsset: record.commonAsset,
            orderAmount: record.orderAmount,
            targetPercentage: record.targetPercentage,
        });
    };

    const fetchBots = async () => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                search,
                sortOrder,
                limit: pageSize,
                offset,
            };
            setLoading(true);
            let { status, result, count } = await getBots(data);
            setLoading(false);
            if (status != false) {
                setBots(result);
                setTotalCount(count);
            }
        } catch (err) {}
    };

    const fetchWallets = async () => {
        try {
            let data = {
                search: walletSearch,
                sortOrder: walletSortOrder,
                limit: walletPageSize,
                offset: walletOffset,
            };
            setWalletLoading(true);
            let { status, result, count } = await getWallet(data);
            setWalletLoading(false);
            if (status != false) {
                setWallets(result);
                setWalletTotalCount(count);
            }
        } catch (err) {}
    };

    const filter = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sort_order = data.sort_order ? data.sort_order : sortOrder;
        setBots([]);
        setOffset(offset);
        setSearch(filter);
        setSortOrder(sort_order);
    };

    const walletFilter = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sort_order = data.sort_order ? data.sort_order : sortOrder;
        setWallets([]);
        setWalletOffset(offset);
        setWalletSearch(filter);
        setWalletSortOrder(sort_order);
    };

    useEffect(() => {
        fetchBots();
    }, [offset, search, sortOrder]);

    useEffect(() => {
        fetchWallets();
    }, [walletOffset, walletSearch, walletSortOrder]);

    return (
        <div>
            <Navbar />
            <div id="wrapper">
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-md-6 mb-4">
                    <div className="tradingbot">
                        <div className="row px-2">
                            <div className="page-bots-layout-content">
                                <div className="ivu-row">
                                    <div className="titleBox">
                                        <h6 className="h6tag mb-4">Create A New Trading Bot</h6>
                                    </div>
                                </div>
                                <div className="BotConfigEditor">
                                    <div className="">
                                        <form
                                            autoComplete="off"
                                            className="ivu-form ivu-form-label-right bot-config-form dark-form"
                                        >
                                            <div className="row mb-4">
                                                <div className="col-md-4">
                                                    <label>Bot Name</label>
                                                </div>
                                                <div className="col-md-8">
                                                <input
                                                            autoComplete="off"
                                                            spellCheck="false"
                                                            type="text"
                                                            maxLength="50"
                                                            className="form-control"
                                                            value={botName}
                                                            name="botName"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                         {validateError.botName && (
                                                        <span className="text-danger fs-6 float-start">
                                                            {
                                                                validateError.botName
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="row mb-4">
                                                <div className="col-md-4">
                                                    <label>Execution Interval</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="group-field">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    value={
                                                        intervalTime
                                                    }
                                                    name="intervalTime"
                                                    onChange={
                                                        handleChange
                                                    }
                                                />
                                                </div>
                                                <div className="col-md-6">
                                                   <Form.Control
                                                        as="select"
                                                        className="form-control"
                                                        value={
                                                            intervalType
                                                        }
                                                        name="intervalType"
                                                        onChange={
                                                            handleChange
                                                        }
                                                    >
                                                        <option
                                                            value={
                                                                "minutes"
                                                            }
                                                        >
                                                            Minutes
                                                        </option>
                                                        <option
                                                            value={
                                                                "hours"
                                                            }
                                                        >
                                                            Hours
                                                        </option>
                                                    </Form.Control> 
                                                    </div>
                                                    </div>  
                                                    </div>
                                                    {validateError.intervalTime && (
                                                        <span className="text-danger fs-6 float-start">
                                                            {
                                                                validateError.intervalTime
                                                            }
                                                        </span>
                                                    )}   
                                                </div>
                                            </div>


                                            
                                            <div className="row mb-4">
                                                <div className="col-md-4">
                                                    <label>Common Asset</label>
                                                </div>   
                                                <div className="col-md-8">
                                                <select
                                                            value={commonAsset}
                                                            className="form-control"
                                                            name="commonAsset"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        >
                                                            <option value="BTC">
                                                                BTC
                                                            </option>
                                                            <option value="BUSD">
                                                                BUSD
                                                            </option>
                                                            <option value="USDT">
                                                                USDT
                                                            </option>
                                                        </select>
                                                        {validateError.commonAsset && (
                                                    <span className="text-danger fs-6 float-start">
                                                        {
                                                            validateError.commonAsset
                                                        }
                                                    </span>
                                                )}
                                                </div>                 
                                            </div>
                                           
                                            <div className="row mb-4">
                                                <div className="col-md-4">
                                                    <label>Max Order Amount</label>
                                                </div>
                                                <div className="col-md-8">
                                                <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onInput={
                                                                validPositive
                                                            }
                                                            name={`orderAmount`}
                                                            value={orderAmount}
                                                        />
                                                        {validateError.orderAmount && (
                                                        <span className="text-danger fs-6 float-start">
                                                            {
                                                                validateError.orderAmount
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4">
                                                    <label>Total Fee (%)</label>
                                                </div>
                                                <div className="col-md-8">
                                                <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onInput={
                                                                validPositive
                                                            }
                                                            name={`targetPercentage`}
                                                            value={
                                                                targetPercentage
                                                            }
                                                        />
                                                         {validateError.targetPercentage && (
                                                        <span className="text-danger fs-6 float-start">
                                                            {
                                                                validateError.targetPercentage
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                            <button
                                                                type="button"
                                                                className="filter_btn"
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                                disabled={
                                                                    loader
                                                                }
                                                            >
                                                                <span>
                                                                    {loader && (
                                                                        <i className="fa fa-spinner fa-spin"></i>
                                                                    )}
                                                                    {!loader &&
                                                                        `${!botId ? "Create":"Update" } Bot`}
                                                                </span>
                                                            </button>
                                            </div>
                                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="col-md-6 mb-4">
                        <div className="tradingbot">
                        <div className="ivu-row">
                            <div className="titleBox">
                                <h6 className="h6tag mb-4">Wallet</h6>
                            </div>
                        </div>
                       
                                    <ReactDatatable
                                        className="historyCryptoTable w-100"
                                        records={wallets}
                                        columns={walletColumns}
                                        config={walletConfigdata}
                                        dynamic={true}
                                        onChange={walletFilter}
                                        loading={walletLoading}
                                        total_record={walletTotalCount}
                                    />
                                                                            
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div id="page-content-wrapper">
                    <div className="container-fluid mb-4">

                    <div className="tradingbot">
                        <div className="row">
                            <div className="page-bots-layout-content">
                            <div className="ivu-row">
                            <div className="titleBox">
                                <h6 className="h6tag mb-4">Bots</h6>
                            </div>
                        </div>
                                
                                
                                    <ReactDatatable
                                        className="historyCryptoTable w-100"
                                        records={bots}
                                        columns={historyColumns}
                                        config={configdata}
                                        dynamic={true}
                                        onChange={filter}
                                        loading={loading}
                                        total_record={totalCount}
                                    />
                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
