import { useState, useEffect } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

// import component
import Navbar from "../partials/Navbar";
import Orders from "./Orders";

// import action
import { getOpportunities } from "../../actions/dashboard";

function Opportunities() {
    // state
    const [loading, setLoading] = useState(true);
    const [opportunities, setOpportunities] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [sortOrder, setSortOrder] = useState({
        column: "_id",
        order: "desc",
    });
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const configdata = {
        page_size: pageSize,
        sortOrder: sortOrder,
        length_menu: [10, 20, 50],
        no_data_text: "No opportunities found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
    };

    const historyColumns = [
        {
            key: "botId",
            text: "Bot Name",
            className: "name",
            sortable: true,
            cell: (record) => {
                return record.botId.name;
            },
        },
        {
            key: "type",
            text: "Type",
            className: "type",
            sortable: true,
        },
        {
            key: "trade",
            text: "Trade",
            className: "trade",
            sortable: false,
            cell: (record) => {
                return record.type=="BUY_BUY_SELL" ? `${record.trade1.secondCurrency}_${record.trade2.secondCurrency}_${record.trade3.firstCurrency}`:`${record.trade1.secondCurrency}_${record.trade2.firstCurrency}_${record.trade3.firstCurrency}`;
            },
        },
        {
            key: "trade1",
            text: "Trade1 Id",
            className: "trade1Id",
            sortable: true,
            cell: (record) => {
                return record.trade1._id;
            },
        },
        {
            key: "trade2",
            text: "Trade2 Id",
            className: "trade2Id",
            sortable: true,
            cell: (record) => {
                return record.trade2._id;
            },
        },
        {
            key: "trade3",
            text: "Trade3 Id",
            className: "trade3Id",
            sortable: true,
            cell: (record) => {
                return record.trade3._id;
            },
        },
        {
            key: "profit",
            text: "Profit",
            className: "profit",
            sortable: true,
        },
        {
            key: "createdAt",
            text: "Executed At",
            className: "createdAt",
            sortable: true,
            cell: (record) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            },
        },
    ];

    const fetchOpportunities = async () => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                search,
                sortOrder,
                limit: pageSize,
                offset,
            };
            setLoading(true);
            let { status, result, count } = await getOpportunities(data);
            setLoading(false);
            if (status != false) {
                setOpportunities(result);
                setTotalCount(count);
            }
        } catch (err) {}
    };

    const filter = (data) => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sort_order = data.sort_order ? data.sort_order : sortOrder;
        setOpportunities([]);
        setOffset(offset);
        setSearch(filter);
        setSortOrder(sort_order);
    };

    useEffect(() => {
        fetchOpportunities();
    }, [offset, search, sortOrder]);

    return (
        <div>
            <Navbar />
            <div className="row px-2">
                <div className="page-opportunities-layout-content my-4">
                <div className="tradingbot">
                <div className="ivu-row">
                                    <div className="titleBox">
                                        <h6 className="h6tag mb-4">Opportunities</h6>
                                    </div>
                                </div>
                        <ReactDatatable
                            className="historyCryptoTable w-100"
                            records={opportunities}
                            columns={historyColumns}
                            config={configdata}
                            dynamic={true}
                            onChange={filter}
                            loading={loading}
                            total_record={totalCount}
                        />
                   
                    </div>
                </div>
                <div className="page-opportunities-layout-content mb-4">
                <div className="tradingbot">
                    <div className="ivu-row">
                                    <div className="titleBox">
                                        <h6 className="h6tag mb-4">Orders</h6>
                                    </div>
                                </div>
                    
                        <Orders />
                   </div>
                </div>
            </div>
        </div>
    );
}

export default Opportunities;