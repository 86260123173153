import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import redux store & provider
// import store from "./store";
import { Provider } from "react-redux";

// import pages
import Dashboard from "./components/pages/Dashboard";
import Opportunities from "./components/pages/Opportunities";
import NotFound from "./components/layout/NotFound";

// import tostify container
import { ToastContainer } from "react-toastify";

// import css
import "./App.css";

function App() {
  return (
    // <Provider store={store}>
      <Router>
        <ToastContainer />
        <div className="App">
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route exact path="dashboard" element={<Dashboard />} />
            </Route>
            <Route exact path="opportunities" element={<Opportunities />} />
            {/* <Route
              exact
              path="/2fa"
              element={<PrivateRoute Component={twofactor} />}
            />
            <Route
              exact
              path="/deposit"
              element={<PrivateRoute Component={Deposit} />}
            />
            <Route
              exact
              path="/withdraw"
              element={<PrivateRoute Component={Withdraw} />}
            />
            <Route
              exact
              path="/withdrawHistory"
              element={<PrivateRoute Component={WithdrawHistory} />}
            /> */}
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    // </Provider>
  );
}

export default App;